import {
  ExtendedFirebaseUIAuthConfig,
  firebase,
  firebaseui,
} from 'firebaseui-angular-i18n';
import {environment} from 'src/environments/environment';

export const firebaseUiAuthConfig: ExtendedFirebaseUIAuthConfig = {
  signInFlow: 'popup',
  signInOptions: [
    // TODO: Activate Google Auth
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      buttonColor: '#3498DB',
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    },
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    },
  ],
  tosUrl: 'http://www.monitoraustral.cl/tos',
  privacyPolicyUrl: 'https://www.monitoraustral.cl/privacidad/',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  language: 'es_419',
  signInSuccessUrl: environment.signInURL,
};
