import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PersonaDTO} from '../models/dbdtos.interface';
import {
  Persona,
  PersonaOrganismoConPersona,
  PersonasProyectosConPersona,
} from '../models/dbresponses.interface';
import {ViaCheckApiQueryService} from './via-check-api-query.service';

@Injectable({
  providedIn: 'root',
})
export class PersonsQuerysService extends ViaCheckApiQueryService {
  public getMe(): Observable<Persona> {
    const subdomain = 'personas/me';
    return this.networkRequest('get', subdomain);
  }

  public getPerson(id: number): Observable<Persona> {
    const subdomain = `personas/${id}`;
    return this.networkRequest('get', subdomain);
  }

  public getPersonsByProject(
    id: number
  ): Observable<PersonasProyectosConPersona[]> {
    const subdomain = `proyectos/${id}/personas`;
    return this.networkRequest('get', subdomain);
  }

  public getPersonsByOrganism(
    id: number
  ): Observable<PersonaOrganismoConPersona[]> {
    const subdomain = `organismos/${id}/personas`;
    return this.networkRequest('get', subdomain);
  }

  public deletePerson(personId: number): Observable<Persona> {
    const subdomain = `personas/${personId}`;
    return this.networkRequest('delete', subdomain);
  }

  public patchPerson(id: number, persona: PersonaDTO): Observable<Persona> {
    const subdomain = `personas/${id}`;
    return this.networkRequest('patch', subdomain, persona);
  }
}
