
<div [class.hide]="!show" class="button-container">
  <p class="text-overlay" 
     (click)="openWhatsapp()">
    Habla con nosotros
  </p>

  <img src="../../../assets/whatspp.png" alt="whatsapp button" 
       (click)="openWhatsapp()">

  <button (click)="closeComponent()">x</button>

</div>
