import {Component, HostBinding} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationEnd, NavigationError, Router} from '@angular/router';

import {SplashScreenPlugin} from '@capacitor/splash-screen';
import {StatusBarPlugin} from '@capacitor/status-bar';
import {Subscription} from 'rxjs';

import {atributesConfig} from '@lib-att/config';
import {AttributesLogicObject} from '@lib-att/lib-att.interfaces';
import {DatabaseService} from './services/sqLiteDatabase.service';
import {FirebaseAuthService} from './services/firebase-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private subscriptions = new Subscription();

  private splashScreen!: SplashScreenPlugin;
  private statusBar!: StatusBarPlugin;

  @HostBinding('class.hide')
  hideLoader = false;

  constructor(
    private router: Router,
    private location: Location,
    private maFireAuth: FirebaseAuthService
  ) {}

  ngOnInit() {
    const reload = this.router.events.subscribe(event => {
      if (
        event instanceof NavigationEnd &&
        this.location.path() &&
        this.location.path() !== '?sesionExpirada=true'
      ) {
        this.maFireAuth.checkUserStatus();
      }
      if (event instanceof NavigationError) {
        console.log('Navigation Error', event.error);
      }
    });

    /** Only for dev */
    //this.printLevel(atributesConfig);

    this.subscriptions.add(reload);
  }

  /** Shows information about project attributes */
  printLevel(a: AttributesLogicObject[], level = 1) {
    for (const obj of a) {
      const i = Array(level).fill(' ').join(' ');
      const d = obj.display + ' (' + obj.dtokey + ') ';
      const p = `[=${obj.parent || ''}]`;
      switch (obj.type.type) {
        case 'title':
        case 'subtitle':
          console.log(`${i}====${obj.display}==== ${p}`);
          break;
        case 'string':
        case 'number':
        case 'boolean':
        case 'email':
        case 'date':
          console.log(`${i}${d}${p} <${obj.type.type}>`);
          break;
        case 'accordion':
        case 'dropdown':
          {
            const v = `<${obj.type.values
              .map(v => v.display)
              .join(',')
              .substring(0, 20)}>`;
            console.log(`${i}${d} ${p} ${v}`);
          }
          break;
        case 'valueList':
          console.log(`${i}${d} ${p} <value List>`);
          break;
      }
      if (obj.children) {
        this.printLevel(obj.children, level + 1);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
