import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {FooterComponent} from './components/footer/footer.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'inicio',
    loadChildren: () =>
      import('./pages/demo/demo.module').then(m => m.DemoPageModule),
  },
  {
    path: 'validar',
    loadChildren: () =>
      import('./pages/validation/validation.module').then(
        m => m.ValidationPageModule
      ),
  },
  {
    path: '',
    component: FooterComponent,
    children: [
      {
        path: 'licitacion',
        loadChildren: () =>
          import(
            './pages/licitation-filtering/licitation-filtering.module'
          ).then(m => m.LicitationFilteringPageModule),
      },
      {
        path: 'organismos',
        children: [
          {
            path: 'invitacion',
            loadChildren: () =>
              import('./pages/invite-from-org/invite-from-org.module').then(
                m => m.InviteFromOrgPageModule
              ),
          },
          {
            path: '',
            loadChildren: () =>
              import('./pages/dashboards-orgs/dashboards-orgs.module').then(
                m => m.DashboardsOrgsPageModule
              ),
          },
        ],
      },
      {
        path: 'proyectos',
        children: [
          {
            path: 'nuevo', // Crear proyecto
            loadChildren: () =>
              import('./pages/new-project/new-project.module').then(
                m => m.NewProjectPageModule
              ),
          },
          {
            path: 'archivados',
            loadChildren: () =>
              import('./pages/archived-projects/archived-projects.module').then(
                m => m.ArchivedProjectsPageModule
              ),
          },
          {
            path: '', // Lista de proyectos del usuario
            loadChildren: () =>
              import('./pages/projects-list/projects-list.module').then(
                m => m.ProjectsListPageModule
              ),
          },
          {
            path: ':id',
            children: [
              {
                path: '', // Revisar detalles de proyecto especifico
                loadChildren: () =>
                  import('./pages/project-configs/project-configs.module').then(
                    m => m.ProjectConfigsPageModule
                  ),
              },
              {
                path: 'editar', // Editar proyecto (Usa la misma lógica de crear uno nuevo)
                loadChildren: () =>
                  import('./pages/new-project/new-project.module').then(
                    m => m.NewProjectPageModule
                  ),
              },
              {
                path: 'firma', // Proceso terminar proyecto
                loadChildren: () =>
                  import('./pages/close-project/close-project.module').then(
                    m => m.CloseProjectPageModule
                  ),
              },
              {
                path: 'invitar', // Vincular una persona la proyecto
                loadChildren: () =>
                  import('./pages/invite/invite.module').then(
                    m => m.InvitePageModule
                  ),
              },
              {
                path: 'participantes', // Listado de participantes de un proyecto especifico
                loadChildren: () =>
                  import('./pages/participantes/participantes.module').then(
                    m => m.ParticipantesPageModule
                  ),
              },
              {
                path: 'atributos', // Crear y revisar detalles de atributos
                children: [
                  {
                    path: 'nuevo', // Crear un nuevo atributo
                    loadChildren: () =>
                      import('./pages/add-attribute/add-attribute.module').then(
                        m => m.AddAttributePageModule
                      ),
                  },
                  {
                    path: ':attributeID', // Detalles/Configs atributos -- Esta página muestra los registros de un atributo y permite hacer cambios a los atributos
                    loadChildren: () =>
                      import(
                        './pages/attribute-configs/attribute-configs.module'
                      ).then(m => m.AttributeConfigsPageModule),
                  },
                ],
              },
              {
                path: 'registros',
                children: [
                  {
                    path: '', // Lista de registros de este proyecto -- Esta página se limita solo a las evidencias generales de un proyecto
                    loadChildren: () =>
                      import(
                        './pages/project-evidence/project-evidence.module'
                      ).then(m => m.ProjectEvidencePageModule),
                  },
                  {
                    path: 'configurar', // Configura el tipo de registros que se solicitará
                    loadChildren: () =>
                      import(
                        './pages/registers-config/registers-config.module'
                      ).then(m => m.RegistersConfigPageModule),
                  },
                  {
                    path: 'nuevo', // Nuevo registro/evidencia
                    loadChildren: () =>
                      import('./pages/new-register/new-register.module').then(
                        m => m.NewRegisterPageModule
                      ),
                  },
                  {
                    path: ':registroID', // Detalle de algún registro
                    loadChildren: () =>
                      import(
                        './pages/registers-details/registers-details.module'
                      ).then(m => m.RegistersDetailsPageModule),
                  },
                ],
              },
              {
                path: 'dashboards/:id',
                loadChildren: () =>
                  import('./pages/dashboards/dashboards.module').then(
                    m => m.DashboardsPageModule
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'usuarios',
        children: [
          {
            path: 'yo',
            loadChildren: () =>
              import('./pages/my-acc/my-acc.module').then(
                m => m.MyAccPageModule
              ),
          },
          // {
          //   // TODO: Los registros en los que el usuario esta involucrado, por ahora es solo una lista random
          //   // TODO: Proximamente debería hacer un display de los proyectos en los que se esta trabajando y los q estna listos
          //   path: 'registros',
          //   loadChildren: () =>
          //     import('./pages/registers/registers.module').then(
          //       m => m.RegistersPageModule
          //     ),
          // },
        ],
      },
    ],
  },
  // TODO: 404
  // {
  //   path: '**',
  //   redirectTo: '',
  //   pathMatch: 'full'
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
