import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PersonsQuerysService} from './personsquerys.service';
import {
  Persona,
  PersonaOrganismoConOrganismo,
} from '../models/dbresponses.interface';
import {OrganismsQuerysService} from './organisms-querys.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(private http: HttpClient) {}

  getUser(): Observable<Persona> {
    const query = new PersonsQuerysService(this.http);
    return query.getMe();
  }

  getOrganisms(id: number): Observable<PersonaOrganismoConOrganismo[]> {
    const query = new OrganismsQuerysService(this.http);
    return query.getOrganismsByPerson(id);
  }
}
