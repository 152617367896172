/* eslint-disable no-useless-escape */
import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {Auth, User, authState, signOut} from '@angular/fire/auth';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  authState$ = authState(this.auth);
  authStateSubscription!: Subscription;
  validationPage = /^\/(validar\?id=)(\d+)(\&secreto=)(\w+-\w+-\w+-\w+-\w+)/;
  tryNumber = 0;

  constructor(
    private auth: Auth,
    private router: Router,
    private location: Location,
    private alertController: AlertController
  ) {}

  ngOnInit() {}

  /**
   * Check if there is a user logged in and handle the session expiration
   */
  async checkUserStatus() {
    this.authStateSubscription = this.authState$.subscribe({
      next: async (aUser: User | null) => {
        /**
         * This function doesnt apply in the validate email page
         */
        if (
          !this.validationPage.test(this.location.path()) &&
          this.location.path() !== '/inicio'
        ) {
          this.getTokenOrAlertExpieredSession(aUser);
        }
      },
      error: error => {
        console.log('Error al validar usuario', error);
        this.router.navigate(['/'], {queryParams: {sesionExpirada: 'true'}});
      },
    });
  }

  async getTokenOrAlertExpieredSession(user: User | null) {
    if (this.tryNumber < 3) {
      if (user) {
        const uid = user.uid;
        user.getIdToken();
      } else {
        setTimeout(() => {
          this.tryNumber++;
          this.checkUserStatus();
        }, 800);
      }
    }

    if (this.tryNumber === 3) {
      const alerta = await this.alertController.create({
        header: 'Sesión Expirada',
        subHeader: 'Por favor ingresa nuevamente',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Continuar',
            role: 'confirm',
            handler: async () => {
              await this.router.navigate([''], {
                queryParams: {sesionExpirada: 'true'},
              });

              location.reload();
            },
          },
        ],
      });

      await alerta.present();
    }
  }

  async signOut() {
    await signOut(this.auth);
  }

  ngOnDestroy(): void {
    this.authStateSubscription.unsubscribe();
  }
}
