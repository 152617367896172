import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  catchError,
  delay,
  finalize,
  map,
  Observable,
  retry,
  Subscription,
  tap,
  throwError,
  timeout,
} from 'rxjs';
import {environment} from '../../environments/environment';
import {Auth, getAuth} from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class ViaCheckApiQueryService {
  private subscriptions = new Subscription();
  token!: string;

  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    return throwError(
      () =>
        new Error(
          `Hubo un error. porfavor intenta más tarde. Error: ${error.message} ${error}`
        )
    );
  }

  getCurrentUser(auth: Auth): Promise<string> {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        user?.getIdToken().then(token => resolve(token));
        unsubscribe();
      }, reject);
    });
  }

  private requestTree(
    reqType: 'get' | 'patch' | 'post' | 'delete' | 'upload' | 'blob' | 'sign',
    subdomain: string,
    token: string,
    body?: any | FormData //TODO: actually some of the dtos, try typing this
  ): Observable<any> {
    const header = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
      'Access-Control-Allow-Headers': 'Content-Type, Accept',
      Authorization: `Bearer ${token}`,
    });

    switch (reqType) {
      case 'get':
        return this.http
          .get<any>(`${environment.baseURL}${subdomain}`, {headers: header})
          .pipe(retry(3), catchError(this.handleError));

      case 'patch':
        return this.http
          .patch<any>(`${environment.baseURL}${subdomain}`, body, {
            headers: header,
          })
          .pipe(catchError(this.handleError));

      case 'post':
        return this.http
          .post<any>(`${environment.baseURL}${subdomain}`, body, {
            headers: header,
          })
          .pipe(catchError(this.handleError));

      case 'delete':
        return this.http
          .delete<any>(`${environment.baseURL}${subdomain}`, {headers: header})
          .pipe(catchError(error => this.handleError(error)));

      case 'upload':
        return this.http.post<any>(
          `${environment.baseURL}${subdomain}`,
          body as FormData,
          {
            headers: header,
            reportProgress: true,
            observe: 'events',
          }
        );
      case 'blob':
        return this.http
          .get(`${environment.baseURL}${subdomain}`, {
            headers: header,
            responseType: 'blob',
          })
          .pipe(retry(3), catchError(this.handleError));

      case 'sign':
        return this.http
          .get(`${environment.baseURL}${subdomain}`, {
            headers: header,
            responseType: 'blob',
          })
          .pipe(timeout(30000), retry(1), catchError(this.handleError));
    }
  }

  public networkRequest(
    reqType: 'get' | 'patch' | 'post' | 'delete' | 'upload' | 'blob' | 'sign',
    subdomain: string,
    body?: any //TODO: actually some of the dtos, try typing this
  ): Observable<any> {
    return new Observable(sub => {
      this.getCurrentUser(getAuth()).then(token => {
        const a = this.requestTree(reqType, subdomain, token, body);
        a.pipe(x => x).subscribe(x => {
          sub.next(x);
          sub.complete();
        });
      });
    });
  }
}
