import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss'],
})
export class WhatsappButtonComponent implements OnInit {
  show = true;

  userMsg = 'Hola, necesito ayuda utilizando Monitor Austral.';
  wspNumber = '56962208690';

  constructor() {}

  ngOnInit() {}

  closeComponent() {
    this.show = false;
  }

  openWhatsapp() {
    const msg = this.userMsg.replace(/ /g, '%20');
    const url = `https://wa.me/${this.wspNumber}?text=${msg}`;

    return window.open(url, '_blank');
  }
}
