    <ion-tabs>
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="proyectos"  routerLink="/proyectos">
        <ion-icon name="clipboard-outline"></ion-icon>
        Mis Proyectos
      </ion-tab-button>
      <ion-tab-button tab="usuarios/yo" routerLink="/usuarios/yo">
        <ion-icon name="person-circle-outline"></ion-icon>
        Mi Cuenta
      </ion-tab-button>
      <ion-tab-button  *ngIf="user"
        [class.userFromPublicOrganism]="!user.pertenece_a_org_publico"
        tab="organismos" routerLink="/organismos">
        <ion-icon name="bar-chart-outline"></ion-icon>
        Mis Organismos
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
