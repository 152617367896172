/* eslint-disable no-useless-escape */
import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Persona} from 'src/app/models/dbresponses.interface';
import {CurrentUserService} from 'src/app/services/current-user.service';
import {HttpClient} from '@angular/common/http';

/** This component is activated on app-routing module */
@Component({
  selector: 'ma-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  private subscriptions = new Subscription();

  @HostBinding('class.dontshow')
  validationPage = /^\/(validar\?id=)(\d+)(\&secreto=)(\w+-\w+-\w+-\w+-\w+)/;
  dontshow = false;

  @HostBinding('class.userFromPublicOrganism')
  user!: Persona;

  constructor(
    private http: HttpClient,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit() {
    // TODO: For Browsers this should a menu btw
    const reload = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (
          !this.location.isCurrentPathEqualTo('/') &&
          !this.location.isCurrentPathEqualTo('/inicio') &&
          !this.validationPage.test(this.location.path())
        )
          this.getUser();
      }
    });

    this.subscriptions.add(reload);
  }

  getUser(): void {
    const query = new CurrentUserService(this.http);
    const sub = query.getUser().subscribe(user => {
      this.user = user;
      if (!this.user) throw new Error('No user available.');
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
