import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  CreateOrganismoDTO,
  CreatePersonaOrganismoDTO,
} from '../models/dbdtos.interface';
import {
  PersonaOrganismoConOrganismo,
  Organismo,
  PersonaOrganismo,
  Registro,
  Proyecto,
} from '../models/dbresponses.interface';
import {ViaCheckApiQueryService} from './via-check-api-query.service';

@Injectable({
  providedIn: 'root',
})
export class OrganismsQuerysService extends ViaCheckApiQueryService {
  public getOrganismWithSearch(search: string): Observable<Organismo[]> {
    const subdomain = `organismos?busqueda=${search}`;
    return this.networkRequest('get', subdomain);
  }

  public getSingleOrganism(id?: number): Observable<Organismo> {
    const subdomain = `organismos/${id}`;
    return this.networkRequest('get', subdomain);
  }

  public getSingleOrganismByCompradorCodigoOrganism(
    CompradorCodigoOrganismo: string
  ): Observable<Organismo> {
    const subdomain = `organismos?CompradorCodigoOrganismo=${CompradorCodigoOrganismo}`;
    return this.networkRequest('get', subdomain);
  }

  public getSingleOrganismByCompradorNombreOrganismo(
    name: string
  ): Observable<Organismo> {
    const subdomain = `organismos?CompradorNombreOrganismo=${name}`;
    return this.networkRequest('get', subdomain);
  }

  public getOrganismsByPerson(
    id: number
  ): Observable<PersonaOrganismoConOrganismo[]> {
    const subdomain = `personas/${id}/organismos`;
    return this.networkRequest('get', subdomain);
  }

  public linkPersonToOrganism(
    dto: CreatePersonaOrganismoDTO
  ): Observable<PersonaOrganismo> {
    const subdomain = 'organismos/persona';
    return this.networkRequest('post', subdomain, dto);
  }

  public deletePersonFromOrganism(
    orgID: number,
    personID: number
  ): Observable<Organismo> {
    const subdomain = `organismos/${orgID}/persona/${personID}`;
    return this.networkRequest('delete', subdomain);
  }

  /** latestEvidence is a projec that contains the latest updated evidence */
  public getNews(
    orgID: number
  ): Observable<{latestProject: Proyecto; latestEvidence: Registro}> {
    const subdomain = `organismos/news?orgID=${orgID}`;
    return this.networkRequest('get', subdomain);
  }
}
