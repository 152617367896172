export const environment = {
  production: true,
  firebase: {
    projectId: 'docheckapp-712e4',
    appId: '1:11455411456:web:ffcc31fb8d32df86b26fe5',
    storageBucket: 'docheckapp-712e4.appspot.com',
    apiKey: 'AIzaSyB6IZCYOlhResKWp5IMM1ILesLSPginaE4',
    authDomain: 'docheckapp-712e4.firebaseapp.com',
    messagingSenderId: '11455411456',
    measurementId: 'G-JWB140SY45',
  },
  baseURL: 'https://api.monitoraustral.cl/',
  signInURL: 'https://app.monitoraustral.cl/inicio',
};
